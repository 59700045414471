import logo from './logo.svg';
import './App.css';
import './index.css';

import brian1 from './Brian1.jpg';
import brian2 from './Brian2.jpg';
import brian3 from './Brian3.jpg';
import brian4 from './Brian4.jpg';

function App() {
    return (
        <div className='App bg-slate-800 min-h-[100vh] text-slate-200 flex justify-center'>
            <div className='max-w-[1000px]'>
                <header className='text-4xl p-8'>Brian Fisher is a Scammer</header>

                <p className='text-justify p-8'>
                    Brian Allen Fisher scammed his “friends” for 200K USD from Houston,
                    TX. The people affected include Ms Kim, Rick Abu-Saleh, Ishmael and
                    Clayton Jiang. The website is made so that it might make it harder for
                    Brian Fisher to scam others in the future. <br />
                    <br />
                    Brian Allen Fisher from Pittsburgh, PA was a professional poker player
                    and bookmaker who moved to Houston. In Houston, Brian made friends
                    quickly. People generally liked Brian for his wit and humor as well as
                    impersonation skills. It's especially disheartening to all involved to
                    learn that Brian does not have ethics and is very willing to use his
                    friends' trust for personal gain. Be weary of doing business with
                    Brian.
                </p>

                <div className='grid md:grid-flow-row-dense md:grid-cols-2'>
                    <div>
                        <img src={brian1} alt='Brian1' />
                        <img src={brian2} alt='Brian2' />
                    </div>
                    <div>
                        <img src={brian3} alt='Brian3' />
                        <img src={brian4} alt='Brian4' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
